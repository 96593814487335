.container_edit_tour {
    padding: 16px;
    margin-top: 10rem;
    /* margin-bottom: 20rem; */
  }
  
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 16px;
  }
  
  .styled-table th,
  .styled-table td {
    border: 1px solid #e1e1e1;
    padding: 12px;
    text-align: left;
  }
  
  .styled-table th {
    background-color: #f5f5f5;
    font-weight: bold;
  }
  
  .styled-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .styled-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .styled-table td {
    vertical-align: middle; /* Center align content vertically */
  }
  
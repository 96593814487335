.section_container{
  margin-top: 3rem;
  padding-top: 3rem;
}

.sect-banner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* padding: 4rem; */
    margin-left: 4rem;
    margin-right: 4rem;
    gap: 1rem;
}

.sect-card {
    width: 25%;
    /* height: 280px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .profileImage {
    font-size: 3.5rem; 
    color: var(--white);
  }

  .prof-bg{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    /* text-align: center; */
    align-items: center;
    background: var(--orange500);
  }
  
  .textContainer {
    width: 100%;
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .name {
    /* font-size: 0.9em; */
    font-weight: 600;
    color: var(--black);
    letter-spacing: 0.5px;

    font-size: 1em;
  /* font-weight: 500; */
  /* text-align: justify; */
  /* line-height: 1.6; */
  /* letter-spacing: 0.01em; */
  }

  .textContainer{
    line-height: normal;
  }
  
  .profile {
    font-size: 0.84em;    
    letter-spacing: 0.2px;
    text-align: center;
    /* font-size: 17.5px; */
  font-weight: 500;
  /* text-align: justify; */
  /* line-height: 1.6; */
  }

  .sect-title {
    text-align: center;
    line-height: 10px;
  }

   /* xs */
 @media screen and (max-width: 475px){
  .sect-card{
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  .sect-banner{
    width: 100%;
    padding: 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    /* background: yellow; */
  }

  .sect-title{
    line-height: normal;
  }
}

 /* sm */
@media screen and (475px <= width <= 640px){
  .sect-card{
    width: 100%;
    /* padding: 0; */
    /* margin: 0 auto; */
  }

  .sect-banner{
    width: 100%;
    padding: 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    /* background: yellow; */
  }

  .sect-title{
    line-height: normal;
  }
}

/* md */
@media screen and (640px <= width <= 768px){
  .sect-card{
    width: 100%;
    /* padding: 0; */
    /* margin: 0 auto; */
  }

  .sect-banner{
    width: 100%;
    padding: 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    /* background: yellow; */
  }

  .sect-title{
    line-height: normal;
  }
}

/* lg */
@media screen and (768px <= width <= 1280px){
}
  
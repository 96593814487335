.cont-title {
  position: relative;
  margin-top: 3rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../assets/redcharlie-nf7W_hn6DKQ-unsplash.jpg");
  filter:sepia(60%);
  background-attachment: fixed;
  background-blend-mode: color;
  background-size: cover;
  background-position: center;
}

.faq-div{
  margin-left: 4rem;
  margin-right: 4rem;
  margin-top: 3rem;
}

.accordion-container {
  background-color: #ffffff;
  margin-bottom: 3rem;
}

.accordion-item {
  font-size: var(--title);
  margin: 0 auto;
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--border-color);
  transition: all 0.3s ease-in-out;
  padding-bottom: 1rem; /* Added spacing below each item */
}

.accordion-item:hover {
  opacity: 1;
}

summary {
  user-select: none;
  cursor: pointer;
  list-style: none;
  display: flex;
  align-items: center;
  padding: var(--header-padding);
  color: var(--black);
}

summary:hover {
  text-decoration: underline;
}

summary:hover .accordion-icon {
  opacity: 1;
}

.accordion-icon {
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  right: 1rem;
  transition: all 150ms ease-out;
}

.accordion-icon svg {
  width: var(--icon-size);
  height: var(--icon-size);
  transition: transform 0.3s ease-in-out;
}

/* Remove Marker */
summary::-webkit-details-marker {
  display: none;
}

/* Rotate Icon */
details[open] .accordion-icon svg {
  transform: rotate(180deg);
}

/* New styles for content */
details .txt_p {
  /* color: blue; */
  margin-top: 0.8rem; /* Ensure spacing to the next title is at least 1rem */
}

 /* xs */
 @media screen and (max-width: 475px){
  .faq-div{
    padding: 0;
    margin: 0 auto;
  }
}

 /* sm */
@media screen and (475px <= width <= 640px){
}

/* md */
@media screen and (640px <= width <= 768px){
}

/* lg */
@media screen and (768px <= width <= 1280px){
}
.contact-container {
    /* background-color: #eee; */
    /* max-width: 100%; */
    margin-left: auto;
    margin-right: auto;
    /* padding: 1em; */
  }

  .banner-dest{
    position: relative;
    margin-top: 3rem;
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
  
    background: url("../assets/redcharlie-nf7W_hn6DKQ-unsplash.jpg");
    filter:sepia(60%);
    background-attachment: fixed;
    background-blend-mode: color;
    background-size: cover;
    background-position: center;
}

.map{
  margin-top: 2rem;
  padding-bottom: 1rem;
    margin-bottom: 2rem;
    margin-left: 4rem;
    margin-right: 4rem;
}

.wrapper{
  margin-top: 3rem;
  margin-left: 4rem;
  margin-right: 4rem;
  background-color: #cbd3c7;
  /* background-color: var(--orange100bg); */
}
  
  div.form { background-color: #cbd3c7;
    /* background-color: var(--orange100bg); */
  }
  .contact-wrapper {
    margin: auto 0;
  }
  
  .submit-btn {
    float: left;
  }
  .reset-btn {
    float: right;
  }
  
  .form-headline:after {
    content: "";
    display: block;
    width: 10%;
    padding-top: 10px;
    border-bottom: 3px solid #ec1c24;
  }
  
  .highlight-text {
    color: #ec1c24;
  }
  
  .hightlight-contact-info {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.5;
  }
  
  .highlight-text-grey {
    font-weight: 500;
  }
  
  .email-info {
      margin-top: 20px;
  }
  
  ::-webkit-input-placeholder { /* Chrome */
    font-family: 'Roboto', sans-serif;
  }
  
  .required-input {
    color: black;
  }
    
  h3,
  ul {
    margin: 0;
  }
  
  h3 {
    margin-bottom: 1rem;
  }
  
  .form-input:focus,
  textarea:focus{
    outline: 1.5px solid #ec1c24;
  }
  
  .form-input,
  textarea {
    width: 100%;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
  }
  
  /* .wrapper > * {
    padding: 1em;
  } */
  @media (min-width: 700px) {
    .wrapper {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }
    .wrapper > * {
      padding: 2em 2em;
    }
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  .contacts {
    color: #212d31;
  }
  
  .form {
    background: #fff;
  }
  
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  form label {
    display: block;
  }
  form p {
    margin: 0;
  }
  
  .full-width {
    grid-column: 1 / 3;
  }
  
  button,
  .submit-btn,
  .form-input,
  textarea {
    padding: 1em;
  }
  
  button, .submit-btn {
    background: transparent;
    border: 1px solid #ec1c24;
    color: #ec1c24;
    border-radius: 15px;
    padding: 5px 20px;
    text-transform: uppercase;
  }
  button:hover, .submit-btn:hover,
  button:focus , .submit-btn:focus{
    background: #ec1c24;
    outline: 0;
    color: #eee;
  }
  .error {
    color: #ec1c24;
  }
  
   /* xs */
 @media screen and (max-width: 475px){
 .wrapper, .map{
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0 auto;
    width: 100%;
  }

  .map{
    margin-top: 1rem;
  }
}

 /* sm */
@media screen and (475px <= width <= 640px){
  .wrapper, .map{
    padding: 0;
    margin: 0 auto;
    /* width: 100%; */
  }

  .map{
    margin-top: 1rem;
  }  
}

/* md */
@media screen and (640px <= width <= 768px){
}

/* lg */
@media screen and (768px <= width <= 1280px){
}
:root{
  font-family: 'Quicksand', sans-serif;
  --white: #ffffff;
  --black: #000000;
  --orange500: #f97316;
  --orange900footer: #7c3d12;
  --orange100bg: #ffe6d5;
  --orange600btn:#ea670c;
  --orangetxt:#fff4ed;
  
  --neutral200: #e5e5e5;
  --gray800: #1f2937;
  --gray700: #374151;
}

.App{
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
  scroll-behavior: smooth;
 }

body { 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-icon{
  font-size: 25px;
  color: var(--gray800);
}

.logo-title{
  font-size: 25px;
  font-weight: 400;
  font-family: 'Bruno Ace', sans-serif;
}

.icon{
  font-size: 2rem;
  cursor: pointer;
}

.icon-oth{
  vertical-align: middle;
  font-size: 18px;
  cursor: pointer;
  stroke-width: 2;
}

/* -------custom scrollbar----------- */
::-webkit-scrollbar{
  width: 10px;
}

::-webkit-scrollbar-track{
  background: #f0f0f0;
}

::-webkit-scrollbar-thumb{
  background: var(--gray700);
  border-radius: 10px;
}

.title-primary{
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--gray800);
}

.title-secondary{ 
    font-family: "Berkshire Swash", serif;
    font-weight: 400;
    font-style: normal;   
    color: var(--orange500); 
    font-size: 2.5rem;
}

.txt_cont{
  font-size: 1em;
  font-weight: 500;
  text-align: justify;
  line-height: 1.6;
  letter-spacing: 0.01em;
  text-transform: capitalize;
}

.txt_p{
  font-size: 17.5px;
  font-weight: 500;
  text-align: justify;
  line-height: 1.6;
  /* letter-spacing: 0.03em; */
}

.customBtn{
  background: var(--orange500);
  border-radius: 5px;
  color: var(--white);
  border-color: var(--white);
  font-size: 15px;
  font-weight: 500;
  padding: 12px;
  border-style: none;  
}

.customBtn:hover{
  background: var(--gray700);
}

.banner-title{
  font-family: "Limelight", sans-serif;
    font-weight: 500;
    color: var(--white);
    font-style: normal;
    font-size: 3rem;  
    text-align: center;
}

 /* xs */
 @media screen and (max-width: 475px){
  .title-primary{
    font-size: 1.4rem;
    
  }
  
  .title-secondary{ 
      
      font-size: 1.7rem;
  }

  .banner-title{
    font-size: 2rem;
  }
}

 /* sm */
@media screen and (475px <= width <= 640px){
}

/* md */
@media screen and (640px <= width <= 768px){
}

/* lg */
@media screen and (768px <= width <= 1280px){
}
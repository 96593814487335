.package-sect{
  position: relative;
  margin-top: 3rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background: url("../assets/redcharlie-nf7W_hn6DKQ-unsplash.jpg");
  filter:sepia(60%);
  background-attachment: fixed;
  background-blend-mode: color;
  background-size: cover;
  background-position: center;
}

.package_sides{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    gap: 20px;
}

.rt_package{
    width: 55%;
    height: fit-content;
    /* height: 20rem; */
    border-radius: 10px;
    /* line-height: 5px; */
    /* background: green; */
}

.lt_package{
    width: 25%;
    height: fit-content;
    border-radius: 10px;
    /* margin: 0 auto; */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.package-titles{
    display: flex;
    gap: 20px;
    /* flex-direction: row; */
}

.image-holder{
    width: 100%;
    height: 25rem;
}

.img-package{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: fill;
    overflow: hidden;
    aspect-ratio: 16/9;
}

.lt_package p {
    font-size: 1.2em;
    margin-bottom: 20px;
    text-align: center;
}

.booking-inputs {
    display: flex;
    flex-direction: column;
}

.booking-inputs input,
.booking-inputs button {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
}

.booking-inputs input::placeholder {
    color: #888;
}

.booking-inputs button {
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
}

.booking-inputs button:hover {
    background-color: #0056b3;
}

.info-breaker{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 5px;
}

.tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}
.tabs button {
    padding: 10px 20px;
    border: none;
    /* background: var(--orange500); */
    cursor: pointer;
    font-size: 16px;
}
.tabs button.active {
    background: #ccc;
}
.content {
    padding: 20px;
    border: 1px solid #ccc;
    background: #f9f9f9;
}

.icon-package{
    color: var(--orange500);
    font-weight: 500;
    font-size: 30px;
    stroke-width: 1px;
}


.info-breaker {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust the gap between each package-icons as needed */
  }
  
  .package-icons {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust the gap between the icon and the text as needed */
  }
  
  .package-icons p {
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  
  .gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px 10px;
  }
  
  .gallery img {
    width: 100%;
    max-width: 350px;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .gallery::after {
    content: "";
    width: 350px;
  }
  
 /* xs */
 @media screen and (max-width: 800px){
    .package_sides{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .rt_package, .lt_package{
        width: 100%;
        height: fit-content;
    }
    .lt_package{
        /* padding: 0; */
        /* margin: 0 auto; */        
    }
    
    .booking-inputs{
        width: 90%;
    }
}

 /* sm */
@media screen and (475px <= width <= 640px){
}

/* md */
@media screen and (640px <= width <= 768px){
}

/* lg */
@media screen and (768px <= width <= 1280px){
}
.destinfo-container {
    /* Your existing styles */
  }
  
  .banner-destinfo {
    position: relative;
    margin-top: 3rem;
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;

    background: url("../assets/redcharlie-nf7W_hn6DKQ-unsplash.jpg");
    filter:sepia(60%);
    background-attachment: fixed;
    background-blend-mode: color;
    background-size: cover;
    background-position: center;
  }
  
  .destinfo-img {
    object-fit: cover;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Places the image behind the text */
  }
  
  .banner-sectinfo {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .bannerinfo-title {
    /* color: white;  */
    font-size: 2rem; /* Adjust as necessary */
    text-align: center;
  }
  
  .destinfo_gallery{
    width: 100%;
    padding-left: 4rem;
    padding-right: 4rem;
    /* height: 350px; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }

  .destinfo_box{
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .destinfo-content{
    margin-left: 4rem;
    margin-right: 4rem;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  .info-align{
    text-align: center;
  }

   /* xs */
 @media screen and (max-width: 475px){
  .destinfo-content{
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }

  .destinfo_gallery{
    padding: 0;
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

 /* sm */
@media screen and (475px <= width <= 640px){
  .destinfo_gallery{
    padding: 0;
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

/* md */
@media screen and (640px <= width <= 768px){
  .destinfo_gallery{
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }
}

/* lg */
@media screen and (768px <= width <= 1280px){
  .destinfo_gallery{
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }
}
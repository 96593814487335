
.sign-up-form {
  width: 350px;
  margin: 0 auto;
  padding:10px 40px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.register-form{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.form-group {
  width: 100%; /* Set the width to 250px */
  position: relative;
  margin-bottom: 20px; /* Adjust spacing if needed */
}

input[type="text"],
input[type="password"],
input[type="email"] {
  width: 100%; /* Ensure inputs fill the width of .form-group */
  padding: 12px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

label {
  position: absolute;
  top: 12px;
  left: 8px;
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: 0.2s ease all;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus {
  outline: none;
  border-color: #ff6600;
}

input[type="text"]:focus + label,
input[type="password"]:focus + label,
input[type="email"]:focus + label,
input[type="text"]:valid + label,
input[type="password"]:valid + label,
input[type="email"]:valid + label {
  top: -8px;
  left: 10px;
  font-size: 12px;
  background-color: #fff;
  padding: 0 5px;
  color: #ff6600;
}


.password-input {
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
  font-size: 18px;
  color: #ccc;
}

.btn-continue {
  width: 100%;
  padding: 12px;
  background-color: #ff6600;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
}

.login-link {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: 14px;
}

.l-link {
  color: #007bff;
  text-decoration: none;
}

 /* xs */
 @media screen and (max-width: 475px){
  
.sign-up-form {
  width: 90%;
  padding: 20px;
  margin: 0 auto;
  margin-top: 6rem;
  /* margin-bottom: 3rem; */
}
.form-group {
  width: 100%; /* Set the width to 250px */
  position: relative;
  margin-bottom: 5px; 
}

}

 /* sm */
@media screen and (475px <= width <= 640px){
}

/* md */
@media screen and (640px <= width <= 768px){
}

/* lg */
@media screen and (768px <= width <= 1280px){
}


.upload-tour-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-top: 6rem;
  margin-bottom: 2rem;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.tabs button.active {
  background-color: #0056b3;
}

.tab-content {
  display: flex;
  flex-direction: column;
}

.tab-content input,
.tab-content textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.tab-content input[type="file"] {
  padding: 3px;
}

button[type="submit"] {
  padding: 10px 20px;
  border: none;
  background-color: #28a745;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
  align-self: center;
}

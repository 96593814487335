.tour-sect{
  position: relative;
  margin-top: 3rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background: url("../assets/redcharlie-nf7W_hn6DKQ-unsplash.jpg");
  filter:sepia(60%);
  background-attachment: fixed;
  background-blend-mode: color;
  background-size: cover;
  background-position: center;
}

.tour-section{
  margin-left: 4rem;
  margin-right: 4rem;
}

.tour-titles{
  line-height: 5px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

.card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    line-height: 1em;
  }
 
  .card {
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding-bottom: 20px;
    overflow: hidden;
  }

  .card:hover{
    cursor: pointer;
  }
  .card__image_container{
    width: 100%;
    height: 200px;
    /* object-fit: cover; */
    /* overflow: hidden; */

  }
  .card__image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: fill;
  }
  
  .card__info {
    padding: 15px;
    height: 150px;
  }
  
  .card__title {
    font-size: 24px;
    margin-bottom: 5px;
    text-transform: capitalize;
    color: var(--orange500);
  }
  
  .card__text {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 18px;
  }

  .per, .days{
    font-size: 14px;
    font-weight: bold;
  }

  .card-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .card__details {
    font-size: 15px;
    padding-bottom: 20px; 
    font-weight: bold;   
  }
  
  .card__detail--highlight {
    color: #333;
  }
  
  .icon__tour{
    font-size: 16px;
    padding-right: 3px;
    font-weight: bold;
  }

  .star {
    color: var(--orange500);
    font-size: 20px; 
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 1rem;
  }
  
  .pagination-container svg {
    margin: 0 10px;
  }

  .filter_tour_list{
    margin-bottom: 3rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }
  
   /* xs */
 @media screen and (max-width: 475px){
  .tour-section{
    padding: 0;
    width: 100%;
    margin: 0 auto;
  }

  .tour-titles{
    line-height: normal;
  }

  .card-container{
    display: grid;
    grid-template-columns: repeat(1 , 1fr);
    width: 100%;
  }
}

 /* sm */
@media screen and (475px <= width <= 640px){
  .tour-section{
    padding: 0;
  }

  .tour-titles{
    line-height: normal;
  }

  .card-container{
    display: grid;
    grid-template-columns: repeat(1 , 1fr);
    width: 100%;
  }
}

/* md */
@media screen and (640px <= width <= 1280px){
  .tour-section{
    padding: 0;
    /* width: 100%; */    
  }

  .tour-titles{
    line-height: normal;
  }

  .card-container{
   
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
    width: 100%;
  }
}

/* lg */
@media screen and (768px <= width <= 1280px){
}
  


.news-form {
    /* height: 35vh; */
    padding-top: 3rem;
    padding-bottom: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* margin-top: 3rem; */
      /* background: #2b4522; */
      background: #2C3930;  
      /* padding: 20px; */
      z-index: 2;
    }
    
    .news-title {
      font-size: 30px;
      line-height: 2rem;
      /* font-weight: 700; */
      letter-spacing: -0.025em;
      
      color: var(--black);
    }
/*     
    .description {
      line-height: 1.5rem;
      font-size: 18px;
      font-weight: 500;
      margin-top: 1rem;
      margin-left: 4rem;
      margin-right: 4rem;
      text-align: center;
      color: var(--black);
    } */
    
    .news-form div {
      display: flex;
      max-width: 28rem;
      margin-top: 1rem;
      column-gap: 0.5rem;
    }
    
    .news-form div input {
      outline: none;
      line-height: 1.5rem;
      font-size: 0.875rem;
      padding: 0.5rem 0.875rem;
      background: white;
      border: 1px solid rgba(253, 253, 253, 0.363);
      border-radius: 0.375rem;
      flex: 1 1 auto;
    }
    
    .news-form div input::placeholder {
      color: var(--black);
    }
    
    .news-form div input:focus {
      /* border: 1px solid rgb(99 102 241); */
    }
    
    .news-form div button {
      color: #fff;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding: 0.625rem 0.875rem;
      background: var(--orange500);
      border-radius: 0.375rem;
      border: none;
      outline: none;
    }
  
      /* xs */
  @media screen and (max-width: 475px){
     .news-form{
      /* padding: 0; */
      margin-top: 3rem;
      padding-top: 3rem;
      margin: 0 auto;
     }

     
  }
  
     /* sm */
  @media screen and (max-width: 640px){
     
  }
  
  /* md */
  @media screen and (max-width: 768px){
     
  }
  
  /* lg */
  @media screen and (max-width: 1024px){
     
  }
  
  /* xl */
  @media screen and (max-width: 1280px){
     
  }
  
  /* 2xl */
  @media screen and (max-width: 1536px){
     
  }
    
.branch-container{
    margin-left: 4rem;
    margin-right: 4rem;
    padding-top: 3rem;
}

.branch-img {
    width: 100%;
    height: 60vh; 
    background: #D3D5D2F7;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.branch-img img {
    width: 100%;
    height: 100%;
    object-fit: contain; 
}

.branch-heading{
    /* background: yellow; */
    text-align: center;
}

 /* xs */
 @media screen and (max-width: 475px){
    .branch-container{
        width: 100%;
        padding: 0;
        margin: 0 auto;

        .branch-img {
            width: 100%;
            height: 30vh; 
            /* background: #D3D5D2F7; */
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        
    }
}

 /* sm */
@media screen and (475px <= width <= 640px){
    .branch-img {
        width: 100%;
        height: 30vh; 
        /* background: #D3D5D2F7; */
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

/* md */
@media screen and (640px <= width <= 768px){
}

/* lg */
@media screen and (768px <= width <= 1280px){
}
.gallery_container {
  margin-top: 4rem;
  margin-left: 4rem;
  margin-right: 4rem;
}

.img-dom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.gallery-title {
  text-align: center;
  line-height: 10px;
}

.lt_side {
  width: 50%;
  height: 520px;
}

.img-container {
  width: 100%;
  height: 100%;
}

.img_gal_full {
  width: 100%;
  height: 100%;
  object-fit: fill; 
  transition: transform 0.3s ease-in-out;
}

.rt_side {
  width: 50%;
  height: 520px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.sm_img {
  height: 100%;
  width: 100%;
}

.img_gal {
  width: 100%;
  height: auto; 
  max-height: 250px; 
  transition: transform 0.3s ease-in-out;
}

.img-container {
  position: relative;
  overflow: hidden;
  object-fit: fill; 
  height: 100%; 
}

.in-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.7);
  padding: 10px;
  border-radius: 5px;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.img-container:hover .img_gal, .img-container:hover .img_gal_full {
  transform: scale(1.05);
}

.img-container:hover .in-img {
  opacity: 1;
}

 /* xs */
 @media screen and (max-width: 475px){
  .gallery_container{
    padding: 0;
    padding-top: 3rem;
    margin: 0 auto;
    width: 100%;
  }
  .img-dom{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background: yellow; */
  }

  .lt_side{
    width: 100%;
  }
  .rt_side {
    width: 100%;
    height: 520px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 10px; */
  }

  .gallery-title{
    line-height: normal;
  }
}

 /* sm */
@media screen and (475px <= width <= 768px){
  .gallery_container{
    /* padding: 0; */
    /* margin: 0 auto; */
    /* width: 100%; */
  }
  .img-dom{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background: yellow; */
  }

  .lt_side{
    width: 100%;
  }
  .rt_side {
    width: 100%;
    height: 520px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 10px; */
  }
  .gallery-title{
    line-height: normal;
  }
}

/* md */
@media screen and (640px <= width <= 768px){
}

/* lg */
@media screen and (768px <= width <= 1280px){
}
.banner-dest {
  position: relative;
  margin-top: 3rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;

  
  background: url("../assets/redcharlie-nf7W_hn6DKQ-unsplash.jpg");
  filter:sepia(60%);
  background-attachment: fixed;
  background-blend-mode: color;
  background-size: cover;
  background-position: center;
}

.offer_row {
  margin-left: 4rem;
  margin-right: 4rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px; 
}

.offer_card {
  height: 250px;
  padding: 2em 2em;
  background: linear-gradient(#ffffff 50%, #f97316 50%);
  background-size: 100% 200%;
  background-position: 0 2.5%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
}

.icon-wrapper {
  background-color: var(--orange500);
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
}

.offer_card:hover {
  background-position: 0 100%;
}

.offer_card:hover .icon-wrapper {
  background-color: #ffffff;
  color: var(--orange500);
}

.offer_card:hover h3 {
  color: #ffffff;
}

.offer_card:hover p {
  color: #f0f0f0;
}

.offter-title,
.offer_cont {
  text-align: center;
}

 /* xs */
 @media screen and (max-width: 475px){
  .offer_row{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }
}

 /* sm */
@media screen and (475px <= width <= 640px){
  .offer_row{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
   }
}

/* md */
@media screen and (640px <= width <= 768px){
  .offer_row{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
   }
}

/* lg */
@media screen and (768px <= width <= 1280px){
}
.banner-dest{
  position: relative;
  margin-top: 3rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background: url("../assets/redcharlie-nf7W_hn6DKQ-unsplash.jpg");
  filter:sepia(60%);
  background-attachment: fixed;
  background-blend-mode: color;
  background-size: cover;
  background-position: center;
}

.dest-content{
    /* width: 100%; */
    text-align: center;
    line-height: 10px;
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: 3rem;
    /* padding: 5px; */
}

.destination-container{
    /* margin-bottom: 3rem; */
    width: 100%;
}

.dest_box{
    /* width: 100%; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 4rem;
    margin-right: 4rem;
}

.dest_gallery {
    position: relative;
    display: inline-block;
    height: 350px;
  }
  
  .dest_img {
    width: 100%;
    height: 100%;
  }
  
  .in-imgtxt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .in-float-imgtxt {
    background-color: rgba(0, 0, 0, 0.5); 
    color: white; 
    padding: 10px; 
    font-weight: 600;
    border-radius: 5px; 
    text-transform: capitalize;
    font-size: 18px;
  }

  .in-float-imgtxt:hover{
    cursor: pointer;
  }

   /* xs */
 @media screen and (max-width: 475px){
  .dest-content{
    line-height: normal;
  }

  .dest_box, .dest_gallery, .dest-content{
    padding: 0;
    margin: 0 auto;
  }

  .dest_box{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .dest_gallery{
    width: 100%;
  }  
}

 /* sm */
@media screen and (475px <= width <= 640px){
  .dest-content{
    line-height: normal;
  }

  .dest_box, .dest_gallery{
    padding: 0;
    margin: 0 auto;
  }

  .dest_box{
    margin-left: 2rem;
    margin-right: 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .dest_gallery{
    width: 100%;
  }
}

/* md */
@media screen and (640px <= width <= 768px){
  .dest-content{
    line-height: normal;
  }

  .dest_box, .dest_gallery{
    padding: 0;
    margin: 0 auto;
  }

  .dest_box{
    margin-left: 2rem;
    margin-right: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .dest_gallery{
    width: 100%;
  }
}

/* lg */
@media screen and (768px <= width <= 1280px){
  .dest-content{
    line-height: normal;
  }

  .dest_box, .dest_gallery{
    padding: 0;
    margin: 0 auto;
  }

  .dest_box{
    margin-left: 2rem;
    margin-right: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .dest_gallery{
    width: 100%;
  }
}
  


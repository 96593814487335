/* AdminDestination.css */

.dest-container {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 8rem;
  padding: 20px;
}

.upload-form {
  display: flex;
  flex-direction: column;
}

.upload-form input,
.upload-form textarea {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.upload-form input[type="file"] {
  margin-bottom: 20px;
}

.upload-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.upload-form button:hover {
  background-color: #0056b3;
}

.upload-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.hero-container {
    position: relative;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.video-css {
    width: 100%;
    object-fit: cover;
}

.hero-txt {
    position: absolute;
    width: 100%;
    top: 15rem;
    text-align: center;
    line-height: 0.3rem;
}

.h-title {
    font-family: "Water Brush", cursive;
    font-weight: 400;
    font-size: 5rem;
    color: var(--white);
}

.h-subtle {
    color: var(--white);
    font-size: 2rem;
    font-weight: 500;
    font-style: normal; 
}

.float-div {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.inp-sect {
    padding: 10px; 
    width: 90%;
}

.inp-items {
    background: var(--white);
    /* width: 100%; */
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.input-custom {
    /* width: 150px;  */
    height: 2.4rem;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 5px;
    text-align: start;
}

 /* xs */
 @media screen and (max-width: 768px){
    .hero-container{
        margin-top: 4rem;
        height: 82vh;
        /* background: yellow; */
    }
    .video-bg{
        height: 100%;
    }
    .video-css {
        width: auto; /* Let width adjust automatically */
        height: 100%; /* Ensure video fits the height */
        object-fit: cover; /* Cover the area while maintaining the aspect ratio */
    }
    .h-title{
        font-size: 40px;
    } 
    .h-subtle{
        font-size: 15px;
    }
    .hero-txt {
        /* position: absolute; */
        width: 100%;
        top: 4rem;
        /* text-align: center; */
        line-height: 35px;
    }
}

 /* sm */
 @media screen and (max-width: 640px) {
    .float-div {
      width: 100%;
      padding-top: 30px;
      height: 20vh;
      bottom: 40px;
      /* top: 2rem; */
      /* margin-bottom: 20px;  */
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column; /* Stack items vertically */
    }
  
    .inp-sect {
      width: 95%; /* Adjust width for smaller screens */
    }
  
    .inp-items {
      flex-direction: column; /* Stack input fields vertically */
      gap: 10px; /* Reduce gap between inputs */
      padding-left: 10px;
      padding-right: 10px;
      height: 100%; /* Let the height adjust based on content */
    }
  
    .input-custom {
      width: 100%; /* Make inputs full-width */
    }
  
    
  }

/* md */
@media screen and (640px <= width <= 768px){
    .hero-txt {
        /* position: absolute; */
        width: 100%;
        top: 10rem;
        /* text-align: center; */
        line-height: 35px;
    }
    .h-title{
        font-size: 50px;
    } 
    .h-subtle{
        font-size: 30px;
    }
}

/* lg */
@media screen and (768px <= width <= 1280px){
    .hero-container{
        margin-top: 4rem;
        height: 82vh;
        /* background: yellow; */
    }
    .video-bg{
        height: 100%;
    }
    .video-css {
        width: auto; /* Let width adjust automatically */
        height: 100%; /* Ensure video fits the height */
        object-fit: cover; /* Cover the area while maintaining the aspect ratio */
    }
}
.footer-container{
    /* background: var(--orange900footer); */
    background: #2C3930;  /* fallback for old browsers */
    
    padding: 4rem;
    justify-content: center;
    align-items: center;
    /* margin-top: 3rem; */
}

.ft-top{
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   justify-content: center;
   gap: 3rem;
   /* background: yellow; */
}

.ft-bottom{
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    color: var(--white);
}

.li-ftlinks{
    list-style: none;
    color: var(--white);
    text-decoration: none;
}

.ul-ftlinks{
    padding: 0;
    color: var(--white);
}

.ftlinks{
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: var(--white);
}

.ft-txt{    
    color: var(--white);
}

.lt-side, .sm-row{
   padding: 1rem;
}

.sm-row{
    /* background: yellow; */
    display: flex;
    justify-content: space-evenly;
}

 /* xs */
 @media screen and (max-width: 475px){
    .footer-container{
        padding: 0;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .ft-top{
        display: flex;
        flex-direction: column;
    }

    .sm-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

}

 /* sm */
@media screen and (475px <= width <= 768px){
    .footer-container{
        padding: 0;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .ft-top{
        display: flex;
        flex-direction: column;
    }

    .sm-row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
}

/* md */
@media screen and (640px <= width <= 768px){
}

/* lg */
@media screen and (768px <= width <= 1280px){
    /* .footer-container{
        padding: 0;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .ft-top{
        display: flex;
        flex-direction: column;
    } */
}


.nav-container{
    width: 100%;
    height: 12vh;
    top: 0;
    position: fixed;
    background: var(--white);
    border-bottom: 2px solid var(--neutral200);
    z-index: 5;
}

.nav-container, .ul-links{
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items: center;    
}

.li-links{
    list-style: none;
    align-items: center; 
    font-size: 17px;
    font-weight: 600;
    color: var(--black);
    text-decoration: none;
}

.li-links:hover{
    cursor: pointer;
    font-weight: 600;
    color: var(--orange500);
}

.nav-links{
    flex: 0.8;
    /* background: yellow; */
}

.nav-icons{
    flex: 0.1;
}

.dropdown{
    display: none;
    list-style: none;
    position: absolute;
    background: var(--white);
    padding: 1rem;
    margin-top: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

/* Show Dropdown */
.dropdown.show {
    display: block;
  }

  /* Dropdown Inside */
/* Active Dropdown Indicator (Optional Styling) */
.drop-menu span.active {
    font-weight: bold;
    color: var(--orange500);
  }


.hamburger-menu {
    display: none;
    cursor: pointer;
}

/* Mobile View */
@media (max-width: 880px) {
    .nav-links {
      display: none;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 5rem;
      padding-top: 3rem;
      padding-bottom: 3rem;
      /* left: 0; */
      background: var(--neutral200);
    }
  
    .nav-links.open {
      display: flex;
    }
  
    .ul-links {
      flex-direction: column;
    }
  
    .li-links {
      width: 100%;
      text-align: center;
      padding: 1rem 0;
    }
  
    .dropdown {
      position: static;
      padding: 1rem;
      background: var(--neutral200);
      
    }

    .drop-menu{
      align-items: center;
      justify-content: center;
    }
  
    .dropdown.show {
      display: block;
    }
  
    .hamburger-menu {
      display: block;
    }
  }
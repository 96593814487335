.admin-menu{
    margin-top: 6rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
}

.admin_btn{
    width: 200px;
    height: 100px;
    text-wrap:balance;
    background: var(--orange500);
    font-size: 17px;
}

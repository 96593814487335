.safari_container{
    padding-top: 3rem;
    margin-right: 4rem;
    margin-left: 4rem;
    margin-top: 3rem;
}

.safari_title{
    text-align: center;
    line-height: 10px;
}

 /* xs */
 @media screen and (max-width: 475px){
    .safari_container{
        padding: 0;
        width: 100%;
        margin: 0 auto;

        .safari_title{
            line-height: normal;
        }
    }
}

 /* sm */
@media screen and (475px <= width <= 640px){
    .safari_container{
        padding: 0;
        /* width: 100%; */
        /* margin: 0 auto; */

        .safari_title{
            line-height: normal;
        }

        .safari_box{
            display: grid;
            grid-template-columns: repeat(1,1fr);
        }
    }
}

/* md */
@media screen and (640px <= width <= 768px){
    .safari_container{
        padding: 0;
        width: 100%;
        margin: 0 auto;

        .safari_title{
            line-height: normal;
        }

        .safari_box{
            /* display: grid; */
            /* grid-template-columns: repeat(2,1fr); */
        }
    }
}

/* lg */
@media screen and (768px <= width <= 1280px){
}

.user_dashboard_container {
    margin-top: 6rem;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    /* flex-direction: column; */
    align-items: center;
  }

  .usr_top{ 
    display: flex; 
    flex-direction: row;  
    justify-content: space-evenly; 
    /* gap: 3rem; */
    align-items: center; }
    
  .usr_bottom {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .tablist_container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .pane_container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;
  }
  
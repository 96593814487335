.about-container{
    /* margin-bottom: 3rem; */
}

.about-content{
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: 2rem;
}
.about-grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.banner-about{
    position: relative;
    margin-top: 3rem;
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;

    background: url("../assets/redcharlie-nf7W_hn6DKQ-unsplash.jpg");
    filter:sepia(60%);
    background-attachment: fixed;
    background-blend-mode: color;
    background-size: cover;
    background-position: center; 
}

.about-content-grid{
    /* height: 30rem; */
}

.img_aboutus {
    width: 100%;
    height: 100%;  
  }

  .core-list{
    list-style:decimal;
    line-height: 30px;
    text-align: justify;
  }

   /* xs */
 @media screen and (max-width: 475px){
    .about-content{
        padding: 0;
        margin: 0 auto;
        width: 100%;
    }

    .about-grid{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        /* grid-template-rows: repeat(2, auto); */
    }    

}

 /* sm */
@media screen and (475px <= width <= 768px){
    .about-grid{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}

/* lg */
@media screen and (768px <= width <= 1280px){
}